<template>
  <AppTemplate>
    <v-row no-gutters justify="center" align="center" class="mb-16">
      <v-col cols="12" sm="6" class="pa-5">
        <h1 class="rwygrey--text font-weight-light">Reset Password</h1>
        <v-form
          ref="resetPassword"
          v-model="valid"
          lazy-validation
          v-if="displayResetPasswordForm"
        >
          <v-text-field
            type="password"
            v-model="credentials.password"
            :counter="100"
            label="password"
            :rules="rules.password"
            maxlength="100"
            required
          />
          <v-text-field
            type="password"
            v-model="credentials.password2"
            :counter="100"
            label="verify password"
            :rules="rules.password"
            maxlength="100"
            required
          />
        </v-form>
        <v-btn
          color="secondary"
          class="white--text mr-5"
          :disabled="!valid"
          @click="resetPassword"
          v-if="displayResetPasswordForm"
        >
          Reset Password
        </v-btn>
        <router-link :to="{ name: 'Login' }" class="mr-5">
          Login
        </router-link>
        <router-link :to="{ name: 'ForgotPassword' }">
          Forgot Password
        </router-link>
      </v-col>
    </v-row>
  </AppTemplate>
</template>

<script>
import AppTemplate from "@/templates/AppTemplate.vue";

export default {
  name: "ResetPassword",
  data: () => ({
    credentials: {},
    valid: true,
    displayResetPasswordForm: false,
    rules: {
      password: [
        (v) => !!v || "password is required",
        (v) =>
          (v && v.length > 7) ||
          "the password must be longer than 7 characters",
      ],
    },
  }),
  components: {
    AppTemplate,
  },
  mounted() {
    if (
      typeof this.$route.query.email === "undefined" ||
      typeof this.$route.query.token === "undefined"
    ) {
      this.displayResetPasswordForm = false;
    } else {
      this.credentials.email = this.$route.query.email;
      this.credentials.token = this.$route.query.token;

      this.displayResetPasswordForm = true;
    }
  },

  methods: {
    resetPassword() {
      if (this.$refs.resetPassword.validate()) {
        this.$store.dispatch("progress/loading");

        this.$store.dispatch("authentication/resetPassword", this.credentials);

        this.$store.dispatch("progress/clear");

        this.$router.push({ name: "Login" });
      }
    },
  },
  metaInfo: {
    title: "Reset Password",
  },
};
</script>
