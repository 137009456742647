<template>
  <v-container class="pa-0">
    <v-row
      no-gutters
      justify="center"
      align="center"
      :class="{
        'home-banner-lg': $vuetify.breakpoint.mdAndUp,
        'home-banner-sm': $vuetify.breakpoint.smOnly,
        'home-banner-xs': $vuetify.breakpoint.xsOnly,
      }"
    >
      <v-col>
        <v-row justify="center" align="start" no-gutters>
          <v-col cols="12" sm="3" order="2" order-sm="1">
            <HeaderLogo />
          </v-col>
          <v-spacer />
          <v-col
            cols="12"
            sm="9"
            class="text-right pt-5"
            order="1"
            order-sm="2"
          >
            <TopMenu />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" sm="1"></v-col>
          <v-col cols="12" sm="5">
            <v-row
              align="center"
              justify="center"
              :class="{
                'mt-5 pt-10': $vuetify.breakpoint.lgAndUp,
                'mt-5': $vuetify.breakpoint.smAndDown,
              }"
              no-gutters
            >
              <v-col class="text-center">
                <h1 class="rwygrey--text font-weight-light">
                  This test guides you toward sustainable careers which increase
                  job security, meaningful work and career opportunities!
                </h1>
              </v-col>
            </v-row>
            <v-row
              align="center"
              justify="center"
              class="mt-5"
              :class="{
                'mb-16 pb-16': $vuetify.breakpoint.lgAndUp,
                'mb-10': $vuetify.breakpoint.smAndDown,
              }"
              no-gutters
            >
              <v-col cols="1">
                <v-img src="/images/down-arrow.png" />
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters>
      <v-col class="pa-5">
        <h1 class="rwygrey--text font-weight-light text-center">
          A sustainable career is one that:
        </h1>
      </v-col>
    </v-row>
    <v-row align="start" justify="center" no-gutters>
      <v-col cols="11" sm="4" md="4" align="center" class="pa-5">
        <v-card color="primary" width="246" height="340" tile>
          <template slot="progress">
            <v-progress-linear
              color="secondary"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-img
            src="/images/about-really-enjoy.png"
            height="246"
            aspect-ratio="1"
          />
          <v-card-title
            class="white--text font-weight-light justify-center text-center pa-5"
          >
            You really enjoy!
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="11" sm="4" md="4" align="center" class="pa-5">
        <v-card color="secondary" width="246" height="340" tile>
          <template slot="progress">
            <v-progress-linear
              color="primary"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-img
            src="/images/about-keeps-you-interested-and-learning.png"
            height="246"
            aspect-ratio="1"
          />
          <v-card-title
            class="white--text font-weight-light justify-center text-center pa-5"
          >
            Keeps you interested and learning!
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="11" sm="4" md="4" align="center" class="pa-5">
        <v-card color="rwygrey" width="246" height="340" tile>
          <template slot="progress">
            <v-progress-linear
              color="primary"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-img
            src="/images/about-is-emerging-or-expanding.png"
            height="246"
            aspect-ratio="1"
          />
          <v-card-title
            class="white--text font-weight-light justify-center text-center pa-5"
          >
            Is emerging or expanding!
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="11" sm="4" md="4" align="center" class="pa-5">
        <v-card color="secondary" width="246" height="340" tile>
          <template slot="progress">
            <v-progress-linear
              color="primary"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-img
            src="/images/about-builds-in-demand-skills.png"
            height="246"
            aspect-ratio="1"
          />
          <v-card-title
            class="white--text font-weight-light justify-center text-center pa-5"
          >
            Builds in-demand skills!
          </v-card-title>
        </v-card>
      </v-col>
      <v-col cols="11" sm="4" md="4" align="center" class="pa-5">
        <v-card color="primary" width="246" height="340" tile>
          <template slot="progress">
            <v-progress-linear
              color="secondary"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-img
            src="/images/about-uses-hot-technology.png"
            height="246"
            aspect-ratio="1"
          />
          <v-card-title
            class="white--text font-weight-light justify-center align-center text-center pa-5"
          >
            Uses hot technology!
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters>
      <v-col
        :class="{
          'pt-5 px-16': $vuetify.breakpoint.mdAndUp,
          'pa-5': $vuetify.breakpoint.smAndDown,
        }"
      >
        <h2 class="rwygrey--text font-weight-light text-center">
          Building a sustainable career is easy with the right tools, and you
          deserve a meaningful job with a bright future.
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters>
      <v-col class="pa-5">
        <h2 class="rwygrey--text font-weight-light text-center">
          Find out where you fit and build a plan to get there!
        </h2>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" no-gutters>
      <v-col
        cols="1"
        align="center"
        :class="{
          'pa-5': $vuetify.breakpoint.mdAndUp,
        }"
      >
        <v-img max-width="50" src="/images/down-arrow.png" />
      </v-col>
    </v-row>
    <v-row align="center" justify="center" class="py-10" no-gutters>
      <v-col class="text-center">
        <v-btn color="primary" plain rounded x-large :to="{ name: 'GetReady' }">
          ABOUT THE TEST
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.home-banner-lg {
  background: url("/images/about-banner-bg.png") no-repeat 50% -250%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
}
.home-banner-sm {
  background: url("/images/about-banner-bg.png") no-repeat 50% 0%;
  -webkit-background-size: 100% auto;
  -moz-background-size: 100% auto;
  -o-background-size: 100% auto;
  background-size: 100% auto;
}
.home-banner-xs {
  background: url("/images/about-banner-bg.png") no-repeat 0% 50%;
  -webkit-background-size: auto 100%;
  -moz-background-size: auto 100%;
  -o-background-size: auto 100%;
  background-size: auto 100%;
}

.v-card__title {
  word-break: normal;
}
</style>
<script>
import HeaderLogo from "@/components/ui/HeaderLogo.vue";
import TopMenu from "@/components/ui/TopMenu.vue";

export default {
  name: "About",
  components: {
    HeaderLogo,
    TopMenu,
  },
  methods: {},
  metaInfo: {
    title: "About",
  },
};
</script>
