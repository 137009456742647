<template>
  <AppTemplate>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" class="mt-5">
        <h1 class="rwygrey--text font-weight-light">
          Take the test now by registering for an account
        </h1>
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" align="start">
      <v-col cols="12" sm="6" class="text-left pa-5">
        <RegisterForm
          @form-values="updateRegisterFormValues"
          @validated="validateRegisterForm"
        />
      </v-col>
      <v-col cols="12" sm="6" class="pa-5 text-right">
        <StripeForm
          @form-values="updateStripeFormValues"
          @validated="validateStripeForm"
          v-bind:stripe="stripe"
        />
        <span
          ><h3 class="font-weight-light" style="text-decoration: underline;">
            Total Price: $29
          </h3></span
        >
      </v-col>
    </v-row>
    <v-row no-gutters justify="center" align="center" class="mb-16">
      <v-col cols="12" align="right" class="pa-5">
        <v-btn
          class="pink white--text mr-5"
          @click="register"
          :disabled="!registerFormValidated || !stripeFormValidated"
        >
          Register
        </v-btn>
        <router-link :to="{ name: 'Login' }" class="mr-5">
          Login
        </router-link>
        <router-link :to="{ name: 'ForgotPassword' }">
          Forgot Password
        </router-link>
      </v-col>
    </v-row>
  </AppTemplate>
</template>

<script>
import AppTemplate from "@/templates/AppTemplate.vue";
import RegisterForm from "@/components/auth/RegisterForm.vue";
import StripeForm from "@/components/payments/StripeForm.vue";

import { paymentsService } from "../services";
import { userService } from "../services";

export default {
  name: "Register",
  data: () => ({
    registerForm: {},
    registerFormValidated: Boolean,
    stripe: Object,
    stripeForm: Object,
    stripeFormValidated: Boolean,
    validate: {},
  }),
  components: {
    AppTemplate,
    RegisterForm,
    StripeForm,
  },
  created() {
    // Don't allow registration if user is already logged in
    if (this.$store.state.authentication.authenticated) {
      const userSelf = userService.self();

      if (userSelf.payment_complete) {
        this.$router.push({ name: "JobMarket" });
      }
    }

    this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUB_KEY);
  },
  methods: {
    updateRegisterFormValues(params) {
      this.registerForm = params;
    },
    validateRegisterForm(validated) {
      this.registerFormValidated = validated;
    },
    updateStripeFormValues(params) {
      this.stripeForm = params;
    },
    validateStripeForm(validated) {
      this.stripeFormValidated = validated;
    },
    register() {
      if (this.registerFormValidated && this.stripeFormValidated) {
        this.$store.dispatch("progress/loading");

        // register user
        this.registerUser(this.registerForm).then(
          () => {
            // login so we can get token for stripe
            this.loginUser(
              this.registerForm.email,
              this.registerForm.password
            ).then(
              () => {
                // get stripe intent
                this.getStripeIntent().then(
                  (intent) => {
                    this.stripePayment(intent);
                  },
                  (error) => {
                    this.$store.dispatch("alert/error", error);
                    this.$store.dispatch("progress/clear");
                  }
                );
              },
              (error) => {
                this.$store.dispatch("alert/error", error);
                this.$store.dispatch("progress/clear");
              }
            );
          },
          (errors) => {
            for (let e in errors) {
              this.$store.dispatch("alert/error", errors[e][0]);
            }
            this.$store.dispatch("progress/clear");
          }
        );
      }
    },
    registerUser(credentials) {
      return new Promise((resolve) => {
        if (!this.$store.state.authentication.authenticated) {
          resolve(
            userService.register(
              credentials.email,
              credentials.first_name,
              credentials.last_name,
              credentials.password
            )
          );
        }

        resolve(true);
      });
    },
    loginUser(username, password) {
      return new Promise((resolve) => {
        if (!this.$store.state.authentication.authenticated) {
          resolve(userService.login(username, password));
        }

        resolve(true);
      });
    },
    getStripeIntent() {
      return paymentsService.getIntent();
    },
    async stripePayment(intent) {
      let billingDetails = {
        address: {
          postal_code: this.stripeForm.zipcode,
        },
      };

      const results = await this.stripe.confirmCardPayment(intent, {
        payment_method: {
          card: this.stripeForm.number,
          billing_details: billingDetails,
        },
      });

      if (results.error) {
        this.$store.dispatch("alert/error", results.error.message);
        this.$store.dispatch("progress/clear");
        return false;
      } else {
        this.$store.dispatch("alert/success", "your payment was successful!");
        setTimeout(() => {
          this.$router.push({ name: "JobMarket" });
        }, 2000);
      }
    },
  },
  metaInfo: {
    title: "Register",
  },
};
</script>
