export const progress = {
  namespaced: true,
  state: {
    loading: false,
  },
  actions: {
    loading({
      commit
    }) {
      commit('loading');
    },
    clear({
      commit
    }) {
      commit('clear');
    },
  },
  mutations: {
    loading(state) {
      state.loading = true;
    },
    clear(state) {
      state.loading = false;
    },
  },
}