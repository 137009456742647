import Vue from 'vue';
import VueRouter from 'vue-router';

import Home from '@/views/Home';
import About from '@/views/About';
import Login from '@/views/Login';
import Logout from '@/views/Logout';
import ForgotPassword from '@/views/ForgotPassword';
import GetReady from '@/views/GetReady';
import ResetPassword from '@/views/ResetPassword';
import JobMarket from '@/views/JobMarket';
import QuizKnowledges from '@/views/QuizKnowledges';
import QuizSkills from '@/views/QuizSkills';
import QuizWorkActivities from '@/views/QuizWorkActivities';
import Register from '@/views/Register';
import TermsOfService from '@/views/TermsOfService';
import PrivacyPolicy from '@/views/PrivacyPolicy';
import WhatsNext from '@/views/WhatsNext';

Vue.use(VueRouter);

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: About,
  },
  {
    path: '/get-ready',
    name: 'GetReady',
    component: GetReady,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
  },
  {
    path: '/jobmarket',
    name: 'JobMarket',
    component: JobMarket,
  },
  {
    path: '/jobmarket/test/step1',
    name: 'JobMarketStep1',
    component: QuizKnowledges,
  },
  {
    path: '/jobmarket/test/step2',
    name: 'JobMarketStep2',
    component: QuizSkills,
  },
  {
    path: '/jobmarket/test/step3',
    name: 'JobMarketStep3',
    component: QuizWorkActivities,
  },
  {
    path: '/jobmarket/whats-next',
    name: 'WhatsNext',
    component: WhatsNext,
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: TermsOfService,
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  // otherwise redirect to home
  {
    path: '*',
    redirect: '/'
  }
];

export const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
});

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in
  const publicPages = [
    '/', '/about', '/get-ready', '/forgot-password', '/reset-password',
    '/login', '/register', '/terms-of-service', '/privacy-policy',
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});