<template>
  <AppTemplate>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" class="mt-5">
        <h1 class="rwygrey--text font-weight-light mb-5">
          Test #2: Skills
        </h1>
        <p class="primary--text text-uppercase">
          Select from the 35 broad skills that make up all jobs in the labor
          market. These are basic skills that can be related to any work, school
          or hobbies you have done in the past. If you are a young worker,
          relate the skills to your classwork and creative pursuits. If you are
          a mature worker, use your entire life experience as a mirror to
          evaluate which of the 35 skills are really your TOP skills, or ones
          you would love to develop.
        </p>
        <p class="primary--text">
          <strong>WARNING: BE SURE TO READ… GO SLOW!</strong>
        </p>
        <v-form vref="skills" lazy-validation>
          <v-card class="fill-width" isHidden="true">
            <QuizChoice
              v-for="k in skills"
              :key="k.skill_id"
              v-bind:choice_id="k.skill_id"
              v-bind:name="k.name"
              v-bind:description="k.description"
              v-bind:selected="k.selected"
              v-on:update-selected="updateSelectedSkills"
            />
            <v-row justify="end" no-gutters>
              <v-btn
                color="secondary"
                class="white--text ma-2"
                :to="{ name: 'JobMarketStep1' }"
              >
                Back
              </v-btn>
              <v-btn
                color="secondary"
                class="white--text ma-2"
                :disabled="!valid"
                @click="next"
              >
                Next
              </v-btn>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </AppTemplate>
</template>

<script>
import AppTemplate from "@/templates/AppTemplate.vue";
import QuizChoice from "@/components/quiz/QuizChoice.vue";
import { quizService } from "../services";

export default {
  name: "JobMarketQuizSkills",
  data() {
    return {
      skills: [],
      selected_skills: [],
      valid: false,
    };
  },
  components: {
    AppTemplate,
    QuizChoice,
  },
  mounted() {
    this.$store.dispatch("progress/loading");
    quizService
      .getSkills()
      .then(
        (skills) => {
          this.skills = skills;
        },
        (error) => {
          this.$store.dispatch("alert/error", `Error loading skills: ${error}`);
          this.$vuetify.goTo(0);
        }
      )
      .then(() => {
        this.$store.dispatch("progress/clear");
      });
  },
  methods: {
    updateSelectedSkills: function(k) {
      if (k.selected) {
        if (!this.inArray(k.element, this.selected_skills)) {
          this.selected_skills.push(k.element);
        }
      } else {
        if (this.inArray(k.element, this.selected_skills)) {
          this.deleteFromArray(k.element);
        }
      }

      if (this.selected_skills.length >= 3) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    inArray: function(n, h) {
      var length = h.length;
      for (var i = 0; i < length; i++) {
        if (h[i] == n) return true;
      }
      return false;
    },
    deleteFromArray: function(k) {
      this.selected_skills.splice(this.selected_skills.indexOf(k), 1);
    },
    next: function() {
      this.$store.dispatch("progress/loading");
      quizService.saveSkills(this.selected_skills).then(
        () => {
          this.$router.push({ name: "JobMarketStep3" });
        },
        (error) => {
          this.$store.dispatch("alert/error", `Error saving skills: ${error}`);
          this.$vuetify.goTo(0);
          this.$store.dispatch("progress/clear");
        }
      );
    },
  },
  metaInfo: {
    title: "Skills",
  },
};
</script>
