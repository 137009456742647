export const alert = {
  namespaced: true,
  state: {
    alerts: [],
  },
  actions: {
    success({
      commit
    }, message) {
      commit('addAlert', {
        type: 'success',
        message
      });
    },
    error({
      commit
    }, message) {
      commit('addAlert', {
        type: 'error',
        message
      });
    },
    warning({
      commit
    }, message) {
      commit('addAlert', {
        type: 'warning',
        message
      });
    },
    clear({
      commit
    }) {
      commit('clear');
    },
  },
  mutations: {
    clear(state) {
      state.alerts = [];
    },
    addAlert(state, {
      type,
      message
    }) {
      state.alerts.push({
        type: type,
        message: message,
      });
    },
  },
}