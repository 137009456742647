<template><AppTemplate /></template>

<script>
import AppTemplate from "@/templates/AppTemplate.vue";

export default {
  name: "Logout",
  components: {
    AppTemplate,
  },
  mounted() {
    this.logout();
  },
  methods: {
    logout() {
      const { dispatch } = this.$store;
      dispatch("authentication/logout", this.credentials);
      this.$router.push({
        name: "Login",
      });
    },
  },
  metaInfo: {
    title: "Logout",
  },
};
</script>