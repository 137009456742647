<template>
  <div>
    <v-alert
      v-for="(a, index) in alerts"
      :type="a.type"
      :key="index"
      dense
      dismissible
    >
      {{ a.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "Alert",
  computed: {
    alerts() {
      return this.$store.state.alert.alerts;
    },
  },
  watch: {
    $route() {
      // clear alert on location change
      this.$store.dispatch("alert/clear");
    },
  },
};
</script>
