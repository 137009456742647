<template>
  <v-app>
    <v-container class="pa-0">
      <v-main>
        <Header />

        <transition name="fade" mode="out-in">
          <div class="pa-5">
            <router-view />
          </div>
        </transition>

        <Footer />
      </v-main>
    </v-container>
  </v-app>
</template>

<style scoped>
a {
  text-decoration: none;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

<script>
import Header from "@/components/ui/Header.vue";
import Footer from "@/components/ui/Footer.vue";

export default {
  name: "App",
  data: () => ({}),
  components: {
    Header,
    Footer,
  },
  computed: {
    authenticated() {
      return this.$store.state.authentication.authenticated;
    },
  },
  created() {
    this.$store.dispatch("authentication/self");
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Welcome",
    // all titles will be injected into this template
    titleTemplate: "%s | Find Your Future",
    meta: [
      { charset: "utf-8" },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1",
      },
      {
        vmid: "description",
        name: "description",
        content:
          "Where do you fit in America’s job market? Don’t settle for career assessments with bland results. Let’s find where you really fit so you can build a sustainable career.",
      },
      {
        propery: "og:title",
        content: "Find Your Future",
      },
      {
        propery: "og:description",
        content:
          "Where do you fit in America’s job market? Don’t settle for career assessments with bland results. Let’s find where you really fit so you can build a sustainable career.",
      },
      {
        propery: "og:image",
        content: "/images/home-banner-bg.png",
      },
      {
        name: "twitter:card",
        content: "summary_large_image",
      },
      {
        name: "twitter:image",
        content: "/images/home-banner-bg.png",
      },
    ],
  },
};
</script>
