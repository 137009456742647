<template>
  <AppTemplate>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" class="mt-5">
        <h1 class="rwygrey--text font-weight-light mb-5">
          Test #1: Knowledge Areas
        </h1>
        <p class="primary--text text-uppercase">
          Select from the 33 broad knowledge areas that make up all jobs in the
          labor market. These are closely related to college majors, but don’t
          worry if you don’t have a degree. Just click the basic categories you
          know about, or are interested in learning more about.
        </p>
        <p class="primary--text">
          <strong>WARNING: BE SURE TO READ… GO SLOW!</strong>
        </p>
        <v-form vref="knowledges" lazy-validation>
          <v-card isHidden="true">
            <QuizChoice
              v-for="k in knowledges"
              :key="k.knowledge_id"
              v-bind:choice_id="k.knowledge_id"
              v-bind:name="k.name"
              v-bind:description="k.description"
              v-bind:selected="k.selected"
              v-on:update-selected="updateSelectedKnowledges"
            />
            <v-row justify="end" no-gutters>
              <v-btn
                color="secondary"
                class="white--text ma-2"
                :disabled="!valid"
                @click="next"
              >
                Next
              </v-btn>
            </v-row>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </AppTemplate>
</template>

<script>
import AppTemplate from "@/templates/AppTemplate.vue";
import QuizChoice from "@/components/quiz/QuizChoice.vue";
import { quizService } from "../services";

export default {
  name: "JobMarketQuizKnowledges",
  data() {
    return {
      knowledges: [],
      selected_knowledges: [],
      valid: false,
    };
  },
  components: {
    AppTemplate,
    QuizChoice,
  },
  mounted() {
    this.$store.dispatch("progress/loading");
    quizService
      .getKnowledges()
      .then(
        (knowledges) => {
          this.knowledges = knowledges;
        },
        (error) => {
          this.$store.dispatch(
            "alert/error",
            `Error loading knowledges: ${error}`
          );
          this.$vuetify.goTo(0);
        }
      )
      .then(() => {
        this.$store.dispatch("progress/clear");
      });
  },
  methods: {
    updateSelectedKnowledges: function(k) {
      if (k.selected) {
        if (!this.inArray(k.element, this.selected_knowledges)) {
          this.selected_knowledges.push(k.element);
        }
      } else {
        if (this.inArray(k.element, this.selected_knowledges)) {
          this.deleteFromArray(k.element);
        }
      }

      if (this.selected_knowledges.length >= 3) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    inArray: function(n, h) {
      var length = h.length;
      for (var i = 0; i < length; i++) {
        if (h[i] == n) return true;
      }
      return false;
    },
    deleteFromArray: function(k) {
      this.selected_knowledges.splice(this.selected_knowledges.indexOf(k), 1);
    },
    next: function() {
      this.$store.dispatch("progress/loading");
      quizService
        .saveKnowledges(this.selected_knowledges)
        .then(
          () => {
            this.$store.dispatch("alert/clear");
            this.$router.push({ name: "JobMarketStep2" });
          },
          (error) => {
            this.$store.dispatch(
              "alert/error",
              `Error saving knowledges: ${error}`
            );
            this.$vuetify.goTo(0);
          }
        )
        .then(() => {
          this.$store.dispatch("progress/clear");
        });
    },
  },
  metaInfo: {
    title: "Knowledge Areas",
  },
};
</script>
