<template>
  <AppTemplate>
    <v-row no-gutters justify="center" align="center">
      <v-col cols="12" class="mt-5">
        <h1 class="rwygrey--text font-weight-light mb-5">
          Test #3: Work Activities
        </h1>
        <p class="primary--text text-uppercase">
          Select from the 45 broad Work Activities that make up all jobs in the
          labor market. These are basic work activities that can be related to
          any work, school or hobbies you have done in the past. Only select
          Work Activities that you both enjoy and feel you are or could be
          naturally suited for.
        </p>
        <p class="primary--text">
          <strong>WARNING: BE SURE TO READ… GO SLOW!</strong>
        </p>
        <v-form vref="work_activities" lazy-validation>
          <v-card class="fill-width" isHidden="true">
            <QuizChoice
              v-for="k in work_activities"
              :key="k.work_activity_id"
              v-bind:choice_id="k.work_activity_id"
              v-bind:name="k.name"
              v-bind:description="k.description"
              v-bind:selected="k.selected"
              v-on:update-selected="updateSelectedWorkActivities"
            />
            <v-row justify="end" no-gutters>
              <v-btn
                color="secondary"
                class="white--text ma-2"
                :to="{ name: 'JobMarketStep2' }"
              >
                Back
              </v-btn>
              <v-btn
                color="secondary"
                class="white--text ma-2"
                :disabled="!valid"
                @click="next"
              >
                Finish
              </v-btn>
            </v-row>
          </v-card>
        </v-form>
        <v-row>
          <v-dialog v-model="dialog" max-width="500">
            <v-card>
              <v-card-title justify="center" align="center">
                <h4 class="rwygrey--text font-weight-light">
                  Are you ready to see your test results?
                </h4>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="primary"
                  class="white--text"
                  text
                  @click="dialog = false"
                >
                  Cancel
                </v-btn>

                <v-btn
                  color="secondary"
                  class="white--text"
                  text
                  @click="finish"
                >
                  Yes
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
  </AppTemplate>
</template>

<script>
import AppTemplate from "@/templates/AppTemplate.vue";
import QuizChoice from "@/components/quiz/QuizChoice.vue";
import { quizService } from "../services";

export default {
  name: "JobMarketQuizWorkActivities",
  data() {
    return {
      work_activities: [],
      selected_work_activities: [],
      valid: false,
      dialog: false,
    };
  },
  components: {
    AppTemplate,
    QuizChoice,
  },
  mounted() {
    this.$store.dispatch("progress/loading");
    quizService
      .getWorkActivities()
      .then(
        (work_activities) => {
          this.work_activities = work_activities;
        },
        (error) => {
          this.$store.dispatch(
            "alert/error",
            `Error loading work activites: ${error}`
          );
          this.$vuetify.goTo(0);
        }
      )
      .then(() => {
        this.$store.dispatch("progress/clear");
      });
  },
  methods: {
    updateSelectedWorkActivities: function(k) {
      if (k.selected) {
        if (!this.inArray(k.element, this.selected_work_activities)) {
          this.selected_work_activities.push(k.element);
        }
      } else {
        if (this.inArray(k.element, this.selected_work_activities)) {
          this.deleteFromArray(k.element);
        }
      }

      if (this.selected_work_activities.length >= 1) {
        this.valid = true;
      } else {
        this.valid = false;
      }
    },
    inArray: function(n, h) {
      var length = h.length;
      for (var i = 0; i < length; i++) {
        if (h[i] == n) return true;
      }
      return false;
    },
    deleteFromArray: function(k) {
      this.selected_work_activities.splice(
        this.selected_work_activities.indexOf(k),
        1
      );
    },
    next: function() {
      this.dialog = true;
    },
    finish: function() {
      this.dialog = false;
      this.$store.dispatch("progress/loading");
      quizService.saveWorkActivities(this.selected_work_activities).then(
        () => {
          quizService.finishQuiz().then(
            () => {
              this.$store.dispatch("alert/clear");
              this.$router.push({ name: "JobMarket" });
            },
            (error) => {
              this.$store.dispatch(
                "alert/error",
                `Error finishing test: ${error}`
              );
              this.$vuetify.goTo(0);

              this.$store.dispatch("progress/clear");
            }
          );
        },
        (error) => {
          this.$store.dispatch(
            "alert/error",
            `Error saving work activites: ${error}`
          );
          this.$vuetify.goTo(0);
          this.$store.dispatch("progress/clear");
        }
      );
    },
  },
  metaInfo: {
    title: "Work Activities",
  },
};
</script>
