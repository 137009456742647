<template>
  <AppTemplate>
    <v-row no-gutters justify="center" align="center" class="mb-16">
      <v-col cols="12" sm="6" class="pa-5">
        <h1 class="rwygrey--text font-weight-light">Login</h1>
        <v-form ref="login" v-model="valid" lazy-validation class="my-5">
          <v-text-field
            v-model="credentials.email"
            :counter="70"
            label="Email Address"
            :rules="rules.email"
            :autofocus="true"
            maxlength="70"
            required
          />
          <v-text-field
            type="password"
            v-model="credentials.password"
            :counter="100"
            label="Password"
            :rules="rules.password"
            maxlength="100"
            required
          />
        </v-form>
        <v-btn
          color="secondary"
          class="white--text mr-5"
          :disabled="!valid"
          @click="login"
        >
          Login
        </v-btn>
        <router-link :to="{ name: 'Register' }" class="mr-5">
          Need an account?
        </router-link>
        <router-link :to="{ name: 'ForgotPassword' }">
          Forgot Password
        </router-link>
      </v-col>
    </v-row>
  </AppTemplate>
</template>

<script>
import AppTemplate from "@/templates/AppTemplate.vue";

export default {
  name: "Login",
  data: () => ({
    credentials: {},
    valid: true,
    loading: false,
    rules: {
      email: [
        (v) => !!v || "email is required",
        (v) => /.+@.+/.test(v) || "email must be valid",
        (v) =>
          (v && v.length <= 128) ||
          "the email must be no longer than 128 characters",
      ],
      password: [(v) => !!v || "password is required"],
    },
  }),
  components: {
    AppTemplate,
  },
  created() {
    // Don't allow registration if user is already logged in
    if (this.$store.state.authentication.authenticated) {
      this.$router.push({ name: "JobMarket" });
    }
  },
  methods: {
    login() {
      if (this.$refs.login.validate()) {
        this.$store.dispatch("progress/loading");
        this.$store
          .dispatch("authentication/login", this.credentials)
          .then(() => {
            this.$store.dispatch("progress/clear");
          });
      }
    },
  },
  metaInfo: {
    title: "Login",
  },
};
</script>
