<template>
  <v-container class="pa-0">
    <v-row no-gutters align="center" justify="center" class="mt-5 pa-2">
      <Progress></Progress>
      <Alert></Alert>
    </v-row>
    <v-row justify="center" align="start" no-gutters>
      <v-col cols="12" sm="3" order="2" order-sm="1">
        <HeaderLogo />
      </v-col>
      <v-spacer />
      <v-col cols="12" sm="9" class="text-right pt-5" order="1" order-sm="2">
        <TopMenu />
      </v-col>
    </v-row>
    <v-main>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="12"><slot /></v-col>
      </v-row>
    </v-main>
  </v-container>
</template>

<script>
import Alert from "@/components/Alert.vue";
import HeaderLogo from "@/components/ui/HeaderLogo.vue";
import Progress from "@/components/Progress.vue";
import TopMenu from "@/components/ui/TopMenu.vue";

export default {
  name: "AppTemplate",
  components: {
    Alert,
    HeaderLogo,
    Progress,
    TopMenu,
  },
};
</script>
