<template>
  <v-overlay v-if="loading">
    <v-progress-circular size="150" width="10" color="primary" indeterminate />
  </v-overlay>
</template>

<script>
export default {
  name: "Progress",
  computed: {
    loading() {
      return this.$store.state.progress.loading;
    },
  },
  watch: {
    $route() {
      // clear progress on location change
      this.$store.dispatch("progress/clear");
    },
  },
};
</script>