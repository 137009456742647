<template>
  <v-form ref="register" v-model="valid">
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" align="center">
        <v-text-field
          v-model="credentials.first_name"
          :rules="rules.name"
          label="First name"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6" align="center">
        <v-text-field
          v-model="credentials.last_name"
          :rules="rules.name"
          label="Last name"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" lg="12" align="center">
        <v-text-field
          v-model="credentials.email"
          :rules="rules.email"
          label="Email Address"
          required
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" align="center">
        <v-text-field
          type="password"
          v-model="credentials.password"
          :counter="30"
          label="password"
          :rules="rules.password"
          maxlength="30"
          required
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: "RegisterForm",
  data: () => ({
    valid: false,
    credentials: {},
    rules: {
      name: [
        (v) => !!v || "name is required",
        (v) =>
          (v && v.length <= 128) ||
          "the name must be no longer than 128 characters",
      ],
      email: [
        (v) => !!v || "email is required",
        (v) => /.+@.+/.test(v) || "email must be valid",
        (v) =>
          (v && v.length <= 128) ||
          "the email must be no longer than 128 characters",
      ],
      password: [
        (v) => !!v || "password is required",
        (v) =>
          (v && v.length > 8) ||
          "the password must be longer than 8 characters",
        (v) =>
          (v && v.length <= 30) ||
          "the password must be no longer than 30 characters",
      ],
    },
  }),
  watch: {
    credentials: function() {
      this.$emit("form-values", this.credentials);
    },
    valid() {
      this.$emit("validated", this.valid);
    },
  },
};
</script>
