import Vue from 'vue';
import App from './App.vue';
import {
  router
} from './helpers';
import {
  store
} from './store';
import {
  vuetify
} from './helpers';
import 'vuetify/dist/vuetify.min.css';
import VueSession from 'vue-session';

Vue.config.productionTip = false;

Vue.use(VueSession);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');