import Vue from 'vue';
import Vuex from 'vuex';

import {
  alert
} from './alert.module';
import {
  authentication
} from './authentication.module';
import {
  progress
} from './progress.module';
import {
  self
} from './self.module';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    authentication,
    progress,
    self,
  }
});