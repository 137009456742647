import Vue from 'vue';
import VueMeta from 'vue-meta'
import Vuetify from 'vuetify/lib';

Vue.use(VueMeta);
Vue.use(Vuetify);

export const vuetify = new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#BE1E2D',
        secondary: '#262262',
        accent: '#F1F2F2',
        error: '#ff4d4d',
        warning: '#795548',
        info: '#00bcd4',
        success: '#4caf50',
        rwygrey: "#6D6E70",
        rwblue: "#166edc",
      },
    },
  },
});