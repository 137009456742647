import {
  authHeader,
} from '../helpers';


export const userService = {
  register,
  login,
  logout,
  forgotPassword,
  resetPassword,
  self,
};

function register(email, first_name, last_name, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      first_name,
      last_name,
      password,
    }),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/auth/user`, requestOptions)
    .then(handleResponse)
    .then(results => {
      return results;
    });
}

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password,
    }),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/auth/token`, requestOptions)
    .then(handleResponse)
    .then(user => {
      if (user.token) {
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
}

function logout() {
  localStorage.removeItem('user');
}

function forgotPassword(email) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email
    }),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/auth/change-password`, requestOptions)
    .then(handleResponse)
    .then(results => {
      return results;
    });
}

function resetPassword(email, token, password) {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      token,
      password
    }),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/auth/reset-password`, requestOptions)
    .then(handleResponse)
    .then(results => {
      return results;
    });
}

function self() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(`${process.env.VUE_APP_API_URL}/auth/self`, requestOptions)
    .then(handleResponse)
    .then(results => {
      return results.detail;
    });
}

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if (response.status === 401) {
        return Promise.reject(data);
      }

      const error = (data && data.message) || data;
      return Promise.reject(error);
    }

    return data;
  });
}